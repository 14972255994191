<template>
  <div style="background-color: #FAFAFA;padding-top: 25px">
    <div style="background-color: white">
      <div class="center">
        <div style="height: 100px;padding-top: 35px;padding-bottom: 30px">
          <div class="tixing_xuqiu_parent">
            <div class="tixing">
              媒体报道
            </div>
          </div>
        </div>
        <!--列表-->
        <div style="padding-bottom: 100px">
          <List item-layout="vertical" :loading="listLoading" style="min-height: 200px">

            <list-item v-for="item of mediaReportList" :key="item.id">
              <router-link :to="{path: 'mediaReportDetail',query:{'id':item.id}}" target="_blank" >
                <div
                  style="height: 200px;margin-bottom:20px;border: 1px solid rgba(220, 220, 220, .5);padding: 20px;cursor: pointer">
                  <img style="height: 160px;width: 235px;float: left;object-fit: fill" :src="item.imageUrl"/>
                  <div style="height: 160px;float: left;padding: 0 15px;width: 920px;overflow: hidden">
                    <div class="title" style="font-size: 18px;color: black;font-weight: bold">{{item.title}}</div>
                    <div class="content san_hang_sheng">
                      <p style="line-height: 25px;color: #333333">
                      {{item.introduction}}
                      </p>
                    </div>
                    <div style="font-size: 12px;color: #333333">
                      <span>{{item.createdTime}}</span>
                    </div>
                  </div>

                </div>
              </router-link>

            </list-item>
          </List>

          <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="totalNum" show-elevator
                style="margin-top: 20px;float: right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getMediaReportList} from "../../plugins/api/mediaReportApi";

  export default {
    name: "MediaReport",
    data() {
      return {
        mediaReportList: [],//媒体报告列表
        pageNum: 1,//当前页
        totalNum: 0,//总个数
        listLoading: true,//列表是否在加载中
      }
    },
    mounted() {
      this.getMediaReportList();
    },
    methods: {
      async getMediaReportList() {
        this.listLoading = true;
        const params = {
          pageSize: 10,
          pageNum: this.pageNum
        }
        const json = await getMediaReportList(params).catch(() => this.listLoading = false);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.mediaReportList = json.result.list;
            this.totalNum = json.result.total;
          }
        }
        this.listLoading = false
      },
      onPageChange(pageNum) {
        this.pageNum = pageNum;
        this.getMediaReportList();
      },

    },

  }
</script>

<style scoped lang="scss">
  /deep/ .ivu-page-item-active {
    background-color: #1890FF;
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .content {
    font-size: 14px;
    margin: 20px 0;
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .tixing_xuqiu_parent {
    width: 1200px;
    height: 40px;
    border-bottom: 1px solid #fc7c40;
    line-height: 40px;
  }

  .tixing {
    color: white;
    padding-left: 20px;
    width: 140px;
    height: 40px;
    background-color: #fc7c40;
    line-height: 40px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-size: 20px;
  }

  .tixing:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 40px solid white;
    border-left: 25px solid transparent;
    position: absolute;
    right: 0;
  }
</style>
